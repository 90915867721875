export default [
  {
    fieldKey: 'registered_name',
    type: 'input',
    label: 'Registered Name',
    default: ''
  },
  {
    fieldKey: 'callname',
    type: 'input',
    label: 'Call Name',
    default: ''
  },
  {
    fieldKey: 'registration_num',
    type: 'input',
    label: 'Registration Number',
    default: ''
  },
  {
    fieldKey: 'breeds.name',
    type: 'input',
    label: 'Breed',
    default: ''
  },
  {
    fieldKey: 'status',
    type: 'select',
    label: 'Status',
    options: [
      { value: '', label: 'Both' },
      { value: 'is_active', label: 'Active' },
      { value: 'is_retired', label: 'Retired' }
    ],
    default: ''
  }
]
