export default {
  registered_name: { defaultValue: '' },
  callname: { defaultValue: '' },
  registration_num: { defaultValue: '' },
  prefix_title: { defaultValue: '' },
  akc_litter_num: { defaultValue: '' },
  foreign_reg_num: { defaultValue: '' },
  country_registry: { defaultValue: '' },
  breed_id: { defaultValue: '' },
  kennel_id: { defaultValue: '' },
  gender: { defaultValue: 'M' },
  birthdate: { defaultValue: '' },
  sire: { defaultValue: '' },
  dam: { defaultValue: '' },
  owner: { defaultValue: '' },
  address: { defaultValue: '' },
  city: { defaultValue: '' },
  state: { defaultValue: '' },
  country: { defaultValue: '' },
  zip: { defaultValue: '' },
  microchip: { defaultValue: '' },
  breeder: { defaultValue: '' },
  phone: { defaultValue: '' },
  email: { defaultValue: '' },
  is_active: { defaultValue: true }
}
