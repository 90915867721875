import commonActions from '@/common/store/actions'
import axios from '@/utils/axios'

const rootDispatch = { root: true }
const customActions = {
  async loadKennelLists ({ commit, dispatch }, listPayload) {
    const { uid = '' } = listPayload
    const { type, payload } = await axios({ url: `kennels/kennelOptions/${uid}`, method: 'GET' })
    if (type === 200) {
      const kennelOptions = payload.map(e => ({ value: e.id, label: e.name }))
      kennelOptions.unshift({ value: null, label: 'No Kennel' })
      commit('DOG_SELECTION', { key: 'kennels', data: kennelOptions })
    } else {
      dispatch('addNotification', { type: 'error', message: payload.message }, rootDispatch)
    }
  },
  async loadBreedLists ({ commit, dispatch }) {
    const { type, payload } = await axios({ url: 'breeds/breedOptions', method: 'GET' })
    if (type === 200) {
      const breedOptions = payload.map(e => ({ value: e.id, label: e.name }))
      commit('DOG_SELECTION', { key: 'breeds', data: breedOptions })
    } else {
      dispatch('addNotification', { type: 'error', message: payload.message }, rootDispatch)
    }
  }
}

export default Object.assign(
  {},
  commonActions,
  customActions
)
