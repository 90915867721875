<template>
  <CForm autocomplete="off">
    <CRow>
      <CCol sm="6">
        <CInput
          v-model="item.registered_name"
          name="registered_name"
          label="Registered Name *"
          placeholder="Registered Name"
          :isValid="!errors.registered_name ? null : false"
          :invalidFeedback="errors.registered_name"
          @change="onHandleChange"
        />
      </CCol>
      <CCol sm="6">
        <CInput
          v-model="item.callname"
          name="callname"
          label="Call Name *"
          placeholder="Call Name"
          :isValid="!errors.callname ? null : false"
          :invalidFeedback="errors.callname"
          @change="onHandleChange"
        />
      </CCol>
    </CRow>
    <CRow>
      <CCol sm="12">
        <CInput
          v-model="item.prefix_title"
          name="prefix_title"
          label="Prefix Title"
          placeholder=""
          maxlength="25"
          :isValid="!errors.prefix_title ? null : false"
          :invalidFeedback="errors.prefix_title"
          @change="onHandleChange"
        />
      </CCol>
    </CRow>
    <CRow>
      <CCol sm="6">
        <CInput
          v-model="item.registration_num"
          name="registration_num"
          label="Registration Number *"
          placeholder="Registration Number"
          :isValid="!errors.registration_num ? null : false"
          :invalidFeedback="errors.registration_num"
          @change="onHandleChange"
        />
      </CCol>
      <CCol sm="6">
        <CInput
          v-model="item.country_registry"
          name="country_registry"
          label="Country Registry *"
          placeholder="Country Registry"
          :isValid="!errors.country_registry ? null : false"
          :invalidFeedback="errors.country_registry"
          @change="onHandleChange"
        />
      </CCol>
    </CRow>
    <CRow>
      <CCol sm="6">
        <CInput
          v-model="item.akc_litter_num"
          name="akc_litter_num"
          label="AKC Litter Number"
          placeholder="AKC Litter Number"
          :isValid="!errors.akc_litter_num ? null : false"
          :invalidFeedback="errors.akc_litter_num"
          @change="onHandleChange"
        />
      </CCol>
      <CCol sm="6">
        <CInput
          v-model="item.foreign_reg_num"
          name="foreign_reg_num"
          label="Foreign Registration Number"
          placeholder="Foreign Registration Number"
          :isValid="!errors.foreign_reg_num ? null : false"
          :invalidFeedback="errors.foreign_reg_num"
          @change="onHandleChange"
        />
      </CCol>
    </CRow>
    <CRow>
      <CCol sm="12">
        <CSelect
          :value="item.breed_id"
          name="breed_id"
          label="Breed *"
          placeholder="Select Breed"
          :options="breedLists"
          :isValid="!errors.breed_id ? null : false"
          :invalidFeedback="errors.breed_id"
          @update:value="onInputChanged"
        />
      </CCol>
    </CRow>
    <CRow>
      <CCol sm="6">
        <CInput
          v-model="item.birthdate"
          name="birthdate"
          label="Birthdate *"
          placeholder="01/31/2021"
          :isValid="!errors.birthdate ? null : false"
          :invalidFeedback="errors.birthdate"
          @change="onHandleChange"
        />
      </CCol>
      <CCol sm="6">
        <CSelect
          :value="item.gender"
          name="gender"
          label="Gender"
          :options="[{value: 'M', label: 'Male'}, {value: 'F', label: 'Female'}]"
          @update:value="onInputChanged"
        />
      </CCol>
    </CRow>
    <CRow>
      <CCol sm="12">
        <CInput
          v-model="item.microchip"
          name="microchip"
          label="Microchip #"
          maxlength="15"
          placeholder="Microchip #"
          :isValid="!errors.microchip ? null : false"
          :invalidFeedback="errors.microchip"
          @change="onHandleChange"
        />
      </CCol>
    </CRow>
    <CRow>
      <CCol sm="6">
        <CInput
          v-model="item.sire"
          name="sire"
          label="Sire"
          placeholder="Sire"
          :isValid="!errors.sire ? null : false"
          :invalidFeedback="errors.sire"
          @change="onHandleChange"
        />
      </CCol>
      <CCol sm="6">
        <CInput
          v-model="item.dam"
          name="dam"
          label="Dam"
          placeholder="Dam"
          :isValid="!errors.dam ? null : false"
          :invalidFeedback="errors.dam"
          @change="onHandleChange"
        />
      </CCol>
    </CRow>
    <CRow>
      <CCol sm="12">
        <CInput
          v-model="item.breeder"
          name="breeder"
          label="Breeder"
          placeholder="Breeder"
          :isValid="!errors.breeder ? null : false"
          :invalidFeedback="errors.breeder"
          @change="onHandleChange"
        />
      </CCol>
    </CRow>
    <CRow>
      <CCol sm="12">
        <CSelect
          :value="item.kennel_id"
          name="kennel_id"
          label="Kennel"
          :options="kennelLists"
          @update:value="onInputChanged"
        />
      </CCol>
    </CRow>
    <CRow>
      <CCol sm="12">
        <CInput
          v-model="item.owner"
          name="owner"
          label="Owner *"
          placeholder="Owner"
          :isValid="!errors.owner ? null : false"
          :invalidFeedback="errors.owner"
          @change="onHandleChange"
        />
      </CCol>
    </CRow>
    <CRow>
      <CCol sm="12">
        <CInput
          v-model="item.address"
          name="address"
          label="Address *"
          placeholder="Address"
          :isValid="!errors.address ? null : false"
          :invalidFeedback="errors.address"
          @change="onHandleChange"
        />
      </CCol>
    </CRow>
    <CRow>
      <CCol sm="6">
        <CInput
          v-model="item.city"
          name="city"
          label="City *"
          placeholder="City"
          :isValid="!errors.city ? null : false"
          :invalidFeedback="errors.city"
          @change="onHandleChange"
        />
      </CCol>
      <CCol sm="6">
        <CInput
          v-model="item.state"
          name="state"
          label="State *"
          placeholder="State"
          :isValid="!errors.state ? null : false"
          :invalidFeedback="errors.state"
          @change="onHandleChange"
        />
      </CCol>
    </CRow>
    <CRow>
      <CCol sm="6">
        <CInput
          v-model="item.country"
          name="country"
          label="Country *"
          placeholder="Country"
          :isValid="!errors.country ? null : false"
          :invalidFeedback="errors.country"
          @change="onHandleChange"
        />
      </CCol>
      <CCol sm="6">
        <CInput
          v-model="item.zip"
          name="zip"
          label="Postal/Zip Code *"
          placeholder="Postal/Zip Code"
          :isValid="!errors.zip ? null : false"
          :invalidFeedback="errors.zip"
          @change="onHandleChange"
        />
      </CCol>
    </CRow>
    <CRow>
      <CCol sm="6">
        <CInput
          v-model="item.phone"
          name="phone"
          label="Phone"
          placeholder="Phone"
          :isValid="!errors.phone ? null : false"
          :invalidFeedback="errors.phone"
          @change="onHandleChange"
        />
      </CCol>
      <CCol sm="6">
        <CInput
          v-model="item.email"
          name="email"
          label="Email"
          placeholder="Email"
          :isValid="!errors.email ? null : false"
          :invalidFeedback="errors.email"
          @change="onHandleChange"
        />
      </CCol>
    </CRow>
    <CRow>
      <CCol sm="12">
        <CInputRadio
          :checked="item.is_active"
          name="is_active"
          @update:checked="onInputChanged"
          label="Is Active"
        />
      </CCol>
      <CCol sm="12">
        <CInputRadio
          :checked="item.is_retired"
          name="is_retired"
          @update:checked="onInputChanged"
          label="Is Retired"
        />
      </CCol>
    </CRow>
  </CForm>
</template>

<script>
import inputFields from '../inputFields'
export default {
  name: 'Form',
  props: {
    data: {
      type: Object,
      defaultValue: {}
    },
    errors: {
      type: Object,
      defaultValue: {}
    },
    kennelLists: {
      type: Array,
      defaultValue: []
    },
    breedLists: {
      type: Array,
      defaultValue: []
    }
  },
  data () {
    const defaultFields = Object.keys(inputFields).reduce((data, key) => {
      const { defaultValue } = inputFields[key]
      data[key] = defaultValue
      return data
    }, {})
    const item = Object.assign({}, defaultFields, this.data || {})
    return {
      item
    }
  },
  methods: {
    preventSubmit (event) {
      event.preventSubmit()
      event.preventDefault()
      event.stopPropagation()
    },
    onInputChanged (value, e) {
      const { target: { name } } = e
      this.item = { ...this.item, [name]: value }
      if (name === 'is_retired') {
        this.item.is_active = false
        this.item.is_retired = true
      }
      if (name === 'is_active') {
        this.item.is_retired = false
        this.item.is_active = true
      }
      this.onHandleChange(null, false)
    },
    onHandleChange () {
      this.$emit('itemChange', this.item)
    }
  }
}
</script>
