export default [
  { key: 'registered_name', label: 'Registered Name' },
  { key: 'callname', label: 'Call Name' },
  { key: 'registration_num', label: 'Registration Number' },
  'breed',
  { key: 'birthdate', label: 'Birth Date' },
  'status',
  { key: 'user', label: 'Owner', sorter: false },
  { key: 'action', label: '' }
]
