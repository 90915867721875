var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('CCard',[_c('CCardHeader',[_vm._t("header",[_c('CIcon',{attrs:{"name":_vm.tableIcon}}),_vm._v(" "+_vm._s(_vm.pageTitle)+" "),_c('CButtonGroup',{staticClass:"float-right"},[_c('CButton',{attrs:{"size":"sm","color":"primary"},on:{"click":_vm.onAddNew}},[_c('CIcon',{attrs:{"name":"cil-plus"}}),_vm._v(" Add New ")],1),_c('CButton',{attrs:{"size":"sm","color":"success"},on:{"click":_vm.onRefresh}},[_c('CIcon',{attrs:{"name":"cil-action-redo"}}),_vm._v(" Refresh ")],1)],1)])],2),_c('CCardBody',[_c('PageFilter',{attrs:{"filterData":_vm.filterData,"onFilterUpdate":_vm.onFilter}}),_c('list-pagination',{attrs:{"pageData":_vm.pageData,"perPageSelectData":_vm.perPageSelect,"entity":_vm.entity,"containerClass":"header-pagination"},on:{"pageChange":_vm.changePage}}),_c('div',{staticClass:"lists-table"},[_c('CDataTable',{attrs:{"hover":true,"striped":true,"border":true,"small":true,"fixed":true,"items":_vm.lists,"fields":_vm.fields,"dark":false,"loading":_vm.fetching,"clickableRows":true,"addTableClasses":"header-fixed","sorter":{ external: true },"sorterValue":_vm.sorterValue},on:{"row-clicked":_vm.rowClicked,"update:sorter-value":_vm.updateSort},scopedSlots:_vm._u([{key:"breed",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s((item.breed || {}).name)+" ")])]}},{key:"registered_name",fn:function(ref){
var item = ref.item;
return [(!!item.prefix_title)?_c('td',[_vm._v(" "+_vm._s(item.prefix_title)+" "+_vm._s(item.registered_name)+" ")]):_vm._e(),(!item.prefix_title)?_c('td',[_vm._v(" "+_vm._s(item.registered_name)+" ")]):_vm._e()]}},{key:"user",fn:function(ref){
var item = ref.item;
return [_c('td',{directives:[{name:"start-case",rawName:"v-start-case"}]},[_vm._v(" "+_vm._s(item.user.name)+" ")])]}},{key:"status",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-center"},[_c('CBadge',{attrs:{"color":item.is_active ? 'success' : (item.is_retired ? 'primary' : 'danger')}},[_vm._v(" "+_vm._s(item.is_active ? 'Active' : (item.is_retired ? 'Retired' : 'Inactive'))+" ")])],1)]}},{key:"action",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('CButtonGroup',{attrs:{"size":"sm"}},[_c('CButton',{attrs:{"color":"primary"},on:{"click":function () { return _vm.onEditItem(item); }}},[_vm._v("Edit")]),_c('CButton',{attrs:{"color":"danger"},on:{"click":function () { return _vm.onDeleteItem(item); }}},[_vm._v("Delete")])],1)],1)]}}])})],1),_c('list-pagination',{attrs:{"pageData":_vm.pageData,"perPageSelectData":_vm.perPageSelect,"entity":_vm.entity,"containerClass":"footer-pagination mt-2"},on:{"pageChange":_vm.changePage}})],1)],1),(_vm.newData.show)?_c('Create',{key:_vm.newData.key}):_vm._e(),(!!_vm.editData.data)?_c('Update',{key:_vm.editData.key}):_vm._e(),(!!_vm.deleteData.data)?_c('Delete',{key:_vm.deleteData.key}):_vm._e(),(_vm.detailData.data)?_c('Detail',{key:_vm.detailData.key}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }