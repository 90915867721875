<template>
  <CModal
    :show="true"
    :no-close-on-backdrop="true"
    :centered="true"
    size="lg"
    color="info"
  >
    <template #header>
      <h3 class="modal-title">{{ $options.entitySingle }} Detail - {{ item.id }}</h3>
      <CButtonClose @click="hideForm" class="text-white"/>
    </template>
    <CRow>
      <CCol sm="6">
        <div class="form-group">
          <label>Registered Name </label>
          <strong class="d-block border px-3 py-2">{{ item.registered_name }} </strong>
        </div>
      </CCol>
      <CCol sm="6">
        <div class="form-group">
          <label>Call Name</label>
          <strong class="d-block border px-3 py-2">{{ item.callname }} </strong>
        </div>
      </CCol>
    </CRow>
    <CRow>
      <CCol sm="12">
        <div class="form-group">
          <label>Prefix Title</label>
          <strong class="d-block border px-3 py-2">{{ item.prefix_title }} </strong>
        </div>
      </CCol>
    </CRow>
    <CRow>
      <CCol sm="6">
        <div class="form-group">
          <label>Registration Number</label>
          <strong class="d-block border px-3 py-2">{{ item.registration_num }} </strong>
        </div>
      </CCol>
      <CCol sm="6">
        <div class="form-group">
          <label>Country Registry</label>
          <strong class="d-block border px-3 py-2">{{ item.country_registry }} </strong>
        </div>
      </CCol>
    </CRow>
    <CRow>
      <CCol sm="6">
        <div class="form-group">
          <label>AKC Litter Number</label>
          <strong class="d-block border px-3 py-2">{{ item.akc_litter_num }} </strong>
        </div>
      </CCol>
      <CCol sm="6">
        <div class="form-group">
          <label>Foreign Registration Number</label>
          <strong class="d-block border px-3 py-2">{{ item.foreign_reg_num }} </strong>
        </div>
      </CCol>
    </CRow>
    <CRow>
      <CCol sm="12">
        <div class="form-group">
          <label>Microchip #</label>
          <strong class="d-block border px-3 py-2">{{ item.microchip }} </strong>
        </div>
      </CCol>
    </CRow>
    <CRow>
      <CCol sm="12">
        <div class="form-group">
          <label>Breed</label>
          <strong class="d-block border px-3 py-2">{{ (item.breed || '').name }} </strong>
        </div>
      </CCol>
    </CRow>
    <CRow>
      <CCol sm="6">
        <div class="form-group">
          <label>Birth Date</label>
          <strong class="d-block border px-3 py-2">{{ formatBirthDate(item.birthdate) }} </strong>
        </div>
      </CCol>
      <CCol sm="6">
        <div class="form-group">
          <label>Gender</label>
          <strong class="d-block border px-3 py-2">{{ formatGender(item.gender) }} </strong>
        </div>
      </CCol>
    </CRow>
    <CRow>
      <CCol sm="6">
        <div class="form-group">
          <label>Sire</label>
          <strong class="d-block border px-3 py-2">{{ item.sire }} </strong>
        </div>
      </CCol>
      <CCol sm="6">
        <div class="form-group">
          <label>Dam</label>
          <strong class="d-block border px-3 py-2">{{ item.dam }} </strong>
        </div>
      </CCol>
    </CRow>
    <CRow>
      <CCol sm="12">
        <div class="form-group">
          <label>Breeder</label>
          <strong class="d-block border px-3 py-2">{{ item.breeder }} </strong>
        </div>
      </CCol>
    </CRow>
    <CRow>
      <CCol sm="12">
        <div class="form-group">
          <label>Kennel</label>
          <strong class="d-block border px-3 py-2">{{ item.kennel_name || '&nbsp;' }} </strong>
        </div>
      </CCol>
    </CRow>
    <CRow>
      <CCol sm="12">
        <div class="form-group">
          <label>Owner</label>
          <strong class="d-block border px-3 py-2">{{ item.owner }} </strong>
        </div>
      </CCol>
    </CRow>
    <CRow>
      <CCol sm="12">
        <div class="form-group">
          <label>Address</label>
          <strong class="d-block border px-3 py-2">{{ item.address }} </strong>
        </div>
      </CCol>
    </CRow>
    <CRow>
      <CCol sm="6">
        <div class="form-group">
          <label>City</label>
          <strong class="d-block border px-3 py-2">{{ item.city }} </strong>
        </div>
      </CCol>
      <CCol sm="6">
        <div class="form-group">
          <label>State</label>
          <strong class="d-block border px-3 py-2">{{ item.state }} </strong>
        </div>
      </CCol>
    </CRow>
    <CRow>
      <CCol sm="6">
        <div class="form-group">
          <label>Country</label>
          <strong class="d-block border px-3 py-2">{{ item.country }} </strong>
        </div>
      </CCol>
      <CCol sm="6">
        <div class="form-group">
          <label>Postal/Zip Code</label>
          <strong class="d-block border px-3 py-2">{{ item.zip }} </strong>
        </div>
      </CCol>
    </CRow>
    <CRow>
      <CCol sm="6">
        <div class="form-group">
          <label>Phone</label>
          <strong class="d-block border px-3 py-2">{{ item.phone }} </strong>
        </div>
      </CCol>
      <CCol sm="6">
        <div class="form-group">
          <label>Email</label>
          <strong class="d-block border px-3 py-2">{{ item.email }} </strong>
        </div>
      </CCol>
    </CRow>
    <CRow>
      <CCol sm="12">
        <CBadge :color="item.is_active ? 'success' : 'danger'" size="sm">
          <CIcon :name="item.is_active ? 'cil-check-alt' : 'cil-x'" />
        </CBadge>
        <span class="ml-2">Is Active</span>
      </CCol>
    </CRow>
    <CRow>
      <CCol sm="12">
        <CBadge :color="item.is_retired ? 'success' : 'danger'" size="sm">
          <CIcon :name="item.is_retired ? 'cil-check-alt' : 'cil-x'" />
        </CBadge>
        <span class="ml-2">Is Retired</span>
      </CCol>
    </CRow>
    <template #footer>
      <CButton @click="hideForm" color="success">OK</CButton>
    </template>
  </CModal>
</template>

<script>
import moment from 'moment'
import startCase from 'lodash/startCase'
import { mapState } from 'vuex'
import { entity, entitySingle } from '../entity'

export default {
  name: 'Detail',
  entitySingle: startCase(entitySingle),
  computed: {
    ...mapState({
      item: state => state[entity].detail.data
    })
  },
  methods: {
    formatBirthDate (birthdate) {
      return moment(birthdate).format('MM/DD/YYYY')
    },
    formatGender (gender) {
      if (gender === 'M') {
        return 'Male'
      }
      return 'Female'
    },
    hideForm () {
      this.$store.commit(`${entity}/DETAIL_DATA`, null)
    }
  },
  mounted () {
    document.querySelector('body').classList.add('overflow-hidden')
  },
  destroyed () {
    document.querySelector('body').classList.remove('overflow-hidden')
  }
}
</script>
