import commonMutations from '@/common/store/mutations'

const customMutations = {
  DOG_SELECTION (state, payload) {
    const { key, data } = payload
    state.selections = { ...state.selections, [key]: data }
  }
}

export default Object.assign(
  {},
  commonMutations,
  customMutations
)
