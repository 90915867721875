import commonState from '@/common/store/state'

import { entity, entitySingle } from '../entity'

const customState = {
  entity,
  entitySingle,
  selections: {
    kennels: [],
    breeds: []
  },
  sortData: { column: 'registered_name', direction: 'asc' }
}

export default Object.assign(
  {},
  commonState,
  customState
)
